<template>
  <div class="activityDialog">
    <el-dialog
      top="3%"
      width="500px"
      :title="dialogTitle"
      :visible.sync="visible"
      :close-on-click-modal="false"
      :before-close="closeEvent"
    >
      <el-form ref="dialogForm" :model="form" label-width="120px">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="分类名称">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="权重">
              <el-input v-model="form.weight"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="分类描述">
              <el-input
                type="textarea"
                :rows="3"
                v-model="form.remarks"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeEvent()">取 消</el-button>
        <el-button type="primary" @click="submitData()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dialogTitle: "新增分类",
      headers: {
        authorization: localStorage.getItem("token"),
      },
      memberId: "",
      form: {
        name: "",
        remarks: "",
        weight: "",
      },
    };
  },
  methods: {
    async submitData() {
      let params = { ...this.form };
      params.createTime = new Date().getTime();
      let url = "";
      if (params.id) {
        //修改
        url = "api/admin/productCategory/update";
      } else {
        url = "api/admin/productCategory/add";
      }

      this.getAjax(url, params, "post").then(() => {
        this.$message.success("操作成功");
        this.closeEvent();
        this.$emit("search");
      });
    },
    closeEvent() {
      this.visible = false;
      this.dialogTitle = "新增分类";
      this.form = {};
    },
    getInfo(item) {
      this.visible = true;
      this.dialogTitle = "编辑分类";
      this.form = JSON.parse(JSON.stringify(item));
    },
  },
  mounted() {},
};
</script>

<style lang="less">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
